
import {computed, defineComponent, ref, watch} from 'vue'
import ScanService from "@/core/services/ScanService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import ScanPriorityLabel from "@/views/scan/ScanPriorityLabel.vue";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "ScanEntity",
  components: {DateTimeFormat, Row,  ScanPriorityLabel, EntityLoading},
  props: {
    object: {type: Object, required: true},
  },
  setup(props) {
    const data = ref([]);
    const loading = ref(false);
    const load = (object: any) => {
      loading.value = true;
      ScanService.scanEntity(object.objectType, object.objectId).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    load(props.object);
    const claim = computed(()=>store.state.ClaimModule.claim);
    const caze = computed(()=>store.state.CaseModule.case);
    watch(() => props.object, cb => {
      load(cb);
    })
    return {
      claim,
      loading,
      caze,
      data
    }
  }
})
